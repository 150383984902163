import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import SimpleOptions from "./SimpleOptions";
import AdvancedOptions from "./AdvancedOptions";

class CompressionOptions extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);

		this.state = {
			activeTab: "1",
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	render() {
		return (
			<div>
				<Nav tabs className="mb-2">
					<NavItem>
						<NavLink
							className={ this.state.activeTab === "1" ? "active" : "" }
							onClick={ () => {
								this.toggle("1");
							} }>
							Einstellungen
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={ this.state.activeTab === "2" ? "active" : "" }
							onClick={ () => {
								this.toggle("2");
							} }>
							Experten Einstellungen
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={ this.state.activeTab }>
					<TabPane tabId="1">
						<SimpleOptions/>
					</TabPane>
					<TabPane tabId="2">
						<AdvancedOptions/>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

export default CompressionOptions;
