import { Col, Row } from "reactstrap";
import * as CompressionStatus from "../utils/CompressionStatus";
import React from "react";
import { connect } from "react-redux";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { deleteImage, deleteZIP } from "../state/files";
import IconArchive from "../images/icon-archive.svg";
import IconTrash from "../images/icon-trash.svg";
import DZEntry from "./DZEntry";
import { addFileSubfix } from "../utils/Misc";

const generateZIP = async (zip, images, saveZIP) => {
	let compressedZIP = new JSZip();
	Object.keys(images)
		.forEach(image => compressedZIP.file(images[image].compressedFile.name, images[image].compressedFile));
	try {
		const blob = await compressedZIP.generateAsync({ type: "blob" });

		if (saveZIP)
			saveAs(blob, addFileSubfix(zip.name, "-tinifyme"));
		else
			return blob;

	} catch (err) {
		console.error(err)
	}
};
const generateAllFilesZIP = async (images, zips) => {
	let filesZip = new JSZip();
	Object.keys(images)
		.map(key => images[key])
		.filter(image => !image.zip && image.status === CompressionStatus.COMPRESSION_DONE)
		.forEach(image => filesZip.file(image.compressedFile.name, image.compressedFile));

	const zipData = Object.keys(zips)
		.map(key => zips[key])
		.filter(zip => zip.status === CompressionStatus.ZIP_DONE)
		.map(zip => ({ zip, zipImages: zip.images.map(key => images[key]) }));

	const awaitedZIPs = await Promise.all(zipData.map(async ({ zip, zipImages }) => await generateZIP(zip, zipImages, false)));
	zipData.map((data, index) => ({ ...data, awaitedZIP: awaitedZIPs[index] })).forEach(data => filesZip.file(data.zip.name, data.awaitedZIP));

	filesZip.generateAsync({ type: "blob" })
		.then(blob => saveAs(blob, "tinify-images.zip"))
		.catch(err => console.error(err));
};
const deleteAllItems = (dispatch, images, zips) => {
	Object.keys(zips).forEach(key => dispatch(deleteZIP(key)));
	Object.keys(images).filter(key => !images[key].zip).forEach(key => dispatch(deleteImage(key)));
};

const DZFiles = ({ images, zips, dispatch }) => {
	const files = [...Object.keys(images).filter(key => !images[key].zip), ...Object.keys(zips)]
		.map(key => {
			let file = images[key];

			const isImage = !!file;
			if (!isImage) {
				file = zips[key];
			}

			return { file, isImage, key };
		})
		.sort((a, b) => b.file.timestamp - a.file.timestamp)
		.map(({ file, isImage, key }) => (
			<DZEntry
				fileKey={ key }
				image={ isImage ? file : undefined }
				onContext={ isImage ? () => dispatch(deleteImage(key)) : () => dispatch(deleteZIP(key)) }
				onClick={ isImage ? () => saveAs(file.compressedFile) : () => generateZIP(file, file.images.map(key => images[key]), true)}
				zip={ !isImage ? file : undefined }
				zipImages={ !isImage ? file.images.map(key => images[key]) : undefined }/>
		));

	return (
		<>
			{ files.length !== 0 &&
			<Row className="p-3 mx-0 dz-split-container">
				<Col xs={ 5 }
					 className="dz-split dz-split-danger cursor-pointer mx-0 d-flex flex-row justify-content-center align-items-center"
					 onClick={ () => deleteAllItems(dispatch, images, zips) }>
					<img src={ IconTrash } className="dz-split-icon dz-split-icon-red mr-1" alt="trashcan"/><span className="px-1">Bilder löschen</span>
				</Col>
				<Col xs={ 7 }
					 className="dz-split dz-split-primary cursor-pointer mx-0 d-flex flex-row justify-content-center align-items-center"
					 onClick={ () => generateAllFilesZIP(images, zips) }>
					<img src={ IconArchive }
						 className="dz-split-icon mr-1" alt="archive download"/><span className="px-1">Alle Bilder als ZIP laden</span>
				</Col>
			</Row>
			}
			<Row className="dropzone-list dropzone-files mx-0 mt-2">
				{ files.length !== 0 && files }
			</Row>
		</>
	);
};

export default connect(state => ({
	images: state.files.images,
	zips: state.files.zips,
}), null)(DZFiles);
