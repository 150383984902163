import React, { Component } from "react";

import {
	ButtonGroup,
	Button,
	Col,
	Row,
	Label,
	Input,
	ButtonToolbar,
	FormText,
	CustomInput,
	InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import RangeSlider from "./RangeSlider";
import { connect } from "react-redux";
import { updateLossless, updateNewFilename, updateOption } from "../state/options";

class AdvancedOptions extends Component {

	dispatchEvent = (event) => {
		this.props.dispatch(updateOption(event.target.name, event.target.value === "on" ? event.target.checked : event.target.value));
	};

	dispatchKeyValue = (key, value) => {
		this.props.dispatch(updateOption(key, value));
	};

	render() {
		return (
			<Row>
				<Col sm="12" className="mb-2">
					<Input type="text" name="newFilename" id="newFilename"
							className="round"
							placeholder="Neuer Dateiname"
							value={ this.props.newFilename }
							onChange={ e => this.props.dispatch(updateNewFilename(e.target.value)) }/>
					<FormText>Datein in Eingabe umbenennen. Leer lassen für keine Änderung.</FormText>
				</Col>
				<Col sm="12" className="mb-2">
					<CustomInput type="switch" id="addEnumeration" name="customSwitch"
								checked={ this.props.addEnumeration }
								label={ "Bilder durchzählen" }
								onChange={ e => this.dispatchKeyValue("addEnumeration", e.target.checked) }/>
					<FormText className="mt-0">Wird vernachlässigt ohne einen Dateinamen.</FormText>
				</Col>
				<Col sm="12" className="mt-1 mb-2">
					<Label>
						<CustomInput type="checkbox" id="addSubfix" name="addSubfix" label="'-tinified' an Dateinamen anhängen"
									checked={ this.props.addSubfix }
									onChange={ this.dispatchEvent } />
						<FormText className="mt-0">Wird vernachlässigt ohne einen Dateinamen.</FormText>
					</Label>
				</Col>
				<Col sm="12" className="mt-1 mb-2">
					<div className="d-flex flex-row">
						<span className={`inline-switch pr-2 ${ !this.props.resizeHeight ? 'font-weight-bold' : '' }`}>Breite</span>
						<div className="flex-grow-0 align-self-center">
							<CustomInput type="switch" id="resizeheight" name="customSwitch"
										checked={ this.props.resizeHeight }
										onChange={ e => this.dispatchKeyValue("resizeHeight", e.target.checked) }/>
						</div>
						<span className={`inline-switch pr-2 ${ this.props.resizeHeight ? 'font-weight-bold' : '' }`}>Höhe</span>
						<InputGroup className="flex-grow-1">
							<Input type="number" name="ressize" id="ressize" min="0"
									placeholder="New axis size"
									className="round-left"
									value={ this.props.resizeSize }
									onChange={ e => this.dispatchKeyValue("resizeSize", +e.target.value) }/>
							<InputGroupAddon addonType="append">
								<InputGroupText className="round-right">px</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</div>
					<FormText>Neue Bildbreite/Bildhöhe. Seitenverhältnis bleibt. 0 = nichts verändern. (Nur PNG/JPEG)</FormText>
				</Col>
				<Col sm="12" className="mt-2">
					<Label>
						<CustomInput type="checkbox" id="stripMetadata" name="stripMetadata" label="Meta Daten entfernen"
									checked={ this.props.stripMetadata }
									onChange={ this.dispatchEvent } />
					</Label>
				</Col>
				<Col sm="12" className="mb-2">
					<Label>
						<CustomInput type="checkbox" id="lossless" name="lossless" label="Verlustfreie Kompression"
									checked={ this.props.lossless }
									onChange={ e => this.props.dispatch(updateLossless(e.target.checked)) } />
					</Label>
				</Col>
				<Col sm="12" className="mb-2">
					<Label>PNG Verbesserungsstufe{ this.props.lossless ? " (lossless)" : "" }</Label>
					<ButtonToolbar>
						<ButtonGroup size="sm">
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 1) }
									color={ this.props.pOptLevel === 1 ? "primary" : "secondary" }>1</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 2) }
									color={ this.props.pOptLevel === 2 ? "primary" : "secondary" }>2</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 3) }
									color={ this.props.pOptLevel === 3 ? "primary" : "secondary" }>3</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 4) }
									color={ this.props.pOptLevel === 4 ? "primary" : "secondary" }>4</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 5) }
									color={ this.props.pOptLevel === 5 ? "primary" : "secondary" }>5</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 6) }
									color={ this.props.pOptLevel === 6 ? "primary" : "secondary" }>6</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 7) }
									color={ this.props.pOptLevel === 7 ? "primary" : "secondary" }>7</Button>
							<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 8) }
									color={ this.props.pOptLevel === 8 ? "primary" : "secondary" }>8</Button>
							{ !this.props.lossless &&
							<>
								<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 9) }
										color={ this.props.pOptLevel === 9 ? "primary" : "secondary" }>9</Button>
								<Button onClick={ () => this.dispatchKeyValue("pOptLevel", 10) }
										color={ this.props.pOptLevel === 10 ? "primary" : "secondary" }>10</Button>
							</>
							}
						</ButtonGroup>
					</ButtonToolbar>
				</Col>
				<Col sm="12" className="mt-2 mb-2">
					<RangeSlider label="PNG Mindestqualität" value={ this.props.pQuality }
								onChange={ value => this.props.dispatch(updateOption("pQuality", value)) }
								formatFunction={ value => `${ value }%` }/>
				</Col>
				<Col sm="12" className="mt-2 mb-2">
					<RangeSlider label="JPG Zielqualität" value={ this.props.jQuality }
								onChange={ value => this.props.dispatch(updateOption("jQuality", value)) }
								formatFunction={ value => `${ value }%` }/>
				</Col>
				<Col sm="12" className="mt-2">
					<Label>
						<CustomInput type="checkbox" id="jProgressive" name="jProgressive" label="Progressives JPG"
									checked={ this.props.jProgressive }
									onChange={ this.dispatchEvent } />
					</Label>
				</Col>
				<Col sm="12">
					<Label>
						<CustomInput type="checkbox" id="sRemoveScript" name="sRemoveScript" label={ `<script>'s von SVGs entfernen` }
									checked={ this.props.sRemoveScript }
									onChange={ this.dispatchEvent } />
					</Label>
				</Col>
				<Col sm="12" className="mb-2">
					<Label>
						<CustomInput type="checkbox" id="gInterlaced" name="gInterlaced" label="Interlaced GIF"
									checked={ this.props.gInterlaced }
									onChange={ this.dispatchEvent } />
					</Label>
				</Col>
				<Col sm="12" className="mb-2">
					<Label className="mr-3">GIF Optimierungslevel</Label>
					<ButtonGroup size="sm">
						<Button onClick={ () => this.dispatchKeyValue("gOptLevel", 1) }
								color={ this.props.gOptLevel === 1 ? "primary" : "secondary" }>1</Button>
						<Button onClick={ () => this.dispatchKeyValue("gOptLevel", 2) }
								color={ this.props.gOptLevel === 2 ? "primary" : "secondary" }>2</Button>
						<Button onClick={ () => this.dispatchKeyValue("gOptLevel", 3) }
								color={ this.props.gOptLevel === 3 ? "primary" : "secondary" }>3</Button>
					</ButtonGroup>
				</Col>
				<Col sm="12" className="mt-1 mb-2">
					<RangeSlider label="GIF Verlustsniveau" value={ this.props.gLossLevel }
								onChange={ value => this.props.dispatch(updateOption("gLossLevel", value)) } min={ 1 }
								max={ 300 }/>
				</Col>
			</Row>
		);
	}
}

export default connect(state => ({
	stripMetadata: state.options.stripMetadata,
	lossless: state.options.lossless,
	pOptLevel: state.options.pOptLevel,
	pQuality: state.options.pQuality,
	jQuality: state.options.jQuality,
	jProgressive: state.options.jProgressive,
	sRemoveScript: state.options.sRemoveScript,
	gInterlaced: state.options.gInterlaced,
	gOptLevel: state.options.gOptLevel,
	gLossLevel: state.options.gLossLevel,
	newFilename: state.options.newFilename,
	resizeHeight: state.options.resizeHeight,
	resizeSize: state.options.resizeSize,
	addSubfix: state.options.addSubfix,
	addEnumeration: state.options.addEnumeration,
}), null)(AdvancedOptions);
