import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { FormText } from "reactstrap";
import { compressFile, compressZIP } from "../state/files";
import UrlInput from "./URLInput";
import CloudUpload from "../images/cloud-upload.svg";
import DZFiles from "./DZFiles";
import { isAuthed } from "../utils/Misc";

const getDragClass = (props) => {
	if (props.isDragAccept) {
		return "dragAccept";
	}
	if (props.isDragReject) {
		return "dragReject";
	}
	if (props.isDragActive) {
		return "dragActive";
	}
	return "";
};

function Dropzone(props) {
	const onDrop = useCallback(
		acceptedFiles => acceptedFiles.slice(0,isAuthed(props.token) ? 15:10).forEach(file =>
			props.dispatch(file.type === "application/zip" ? compressZIP(file) : compressFile(file, {}))), []
	);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({ onDrop });

	return (
		<div className="compression-dropzone-container pt-3">
			<div className="px-3">
				<div { ...getRootProps({
					className: `dropzone text-center ${ getDragClass({
						isDragActive,
						isDragAccept,
						isDragReject,
					}) }`,
				}) }>
					<input { ...getInputProps() } />
					<img src={ CloudUpload } className="mb-2 noselect" alt="cloud upload icon"/>
					{
						isDragActive ?
							<p className="mb-0">Fallen lassen</p> :
							<div>
								<p className="mb-1 font-weight-bolder">Bilder per Drag&Drop komprimieren</p>
								<p className="dropzone-smalltext mb-1">oder</p>
								<p className="mb-0 font-weight-bolder">klicken um zu durchsuchen</p>
								<span className="color-orange font-size-12">
									{ isAuthed(props.token)
										? "Die maximale Dateigröße beträgt 25 MB; maximal 15 Bilder!"
										: "Maximale Dateigröße beträgt 8 MB; maximal 10 Bilder. Melde dich an um das Limit zu erhöhen!"
									}
								</span>
							</div>
					}
				</div>
				<UrlInput/>
			</div>
			<FormText className="px-3 font-weight-bolder">Links-klick = Bild herunterladen, Rechts-klick = Bild löschen</FormText>
			<DZFiles/>
		</div>
	);
}

export default connect(state => ({
	token: state.user.token,
}), null)(Dropzone);

