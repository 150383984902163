import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Label } from "reactstrap";

class RangeSlider extends Component {

	onChange = (value) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	};

	render() {
		return (
			<div className="clearfix rc-slider-clearfix">
				<Label for={ this.props.label } className="mb-1">{ this.props.label }: <span
				// <Label for={ this.props.label.replace(/ /g, "-") } className="mb-1">{ this.props.label }: <span
					className="font-weight-bold">{ this.props.formatFunction ? this.props.formatFunction(this.props.value) : this.props.value }</span></Label>
				<Slider
					min={ this.props.min || 0 }
					max={ this.props.max || 100 }
					step={ this.props.step || 1 }
					value={ this.props.value }
					onChange={ this.onChange }
				/>
			</div>
		);
	}
}

export default RangeSlider;
