import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Col, Row } from "reactstrap";
import Dropzone from "../components/Dropzone";
import CompressionOptions from "../components/CompressionOptions";
import ArrowDashedLeft from "../images/arrow-dashed-left.svg";
import ContentContainer from "../components/ContentContainer";

const Index = () => (
	<Layout>
		<SEO title="Schnelle Webseiten dank Bilderkompression. Tinify.me ist dein Lieblings Bilder Kompressor. Jetzt ausprobieren!"/>
		<ContentContainer titleRowClass="mb-5" centerTitle title="Deutschlands schnellster Bild Kompressor" subtitle={<>tinify.me ist dein ultra-schneller und leistungsstarker Bild Kompressor, der die Speichergröße deiner Bilder drastisch reduzieren kann. Durch den innovativen Algorithmus können wir deine Bilder fast ohne Qualitätsunterschiede komprimieren. Dadurch sparst du Speicherplatz und verkürzt die Ladezeit deiner Webseite!</>}>
			<Row>
				<Col xs={{ size: 12 }} md={{ size: 5 }}>
					<h4 className="mb-3 font-weight-bold color-greyblue">1. Wähle die Bildqualität</h4>
					<CompressionOptions/>
					<h4 className="mt-5 mb-3 font-weight-bold color-greyblue">2. Lade deine Bilder hoch</h4>
					<img className="d-none d-md-block m-auto transform-mirror-x noselect" src={ ArrowDashedLeft } alt="arrow to the right"/>
				</Col>
				<Col xs={{ size: 12, }} md={{ size: 7 }}>
					<Dropzone/>
				</Col>
			</Row>
		</ContentContainer>
		<ContentContainer>
			<Col xs={{ size: 12 }}>
				<h4>SEO-Optimierung durch komprimierte Bilder</h4>
				<p>Die Ladezeit deiner Webseite ist in den letzten Monaten und Jahren SEO-technisch immer wichtiger geworden. Umso schneller deine Website lädt, desto besser fürs SEO-Ranking. Häufig sind zu große Bilder der ausschlaggebende Grund für eine lange Ladezeit. Und sind wir mal ganz ehrlich: Langsame Webseiten mag doch niemand, oder? 😉</p>
				<p>tinify.me verkleinert die Speichergröße deiner Bilder um bis zu 90% und das fast ohne Qualitätsverlust. Schau dir einfach unsere Vorher/Nachher Bilder an und entscheide selbst, ob du Unterschiede finden kannst. Durch diese kleineren Bilder lädt deine Seite schneller. Das kann positive Auswirkungen auf dein SEO-Ranking haben und macht deine Webseitenbesucher glücklich!</p>
				<h4>Der beste Bildkompressor</h4>
				<p>Ob wir für dich wirklich der „beste“ Bildkompressor sind, musst du selbst entscheiden. Wir versprechen dir aber, dass der innovative Algorithmus alles gibt, um deine Bilder fast ohne Qualitätsverlust zu optimieren. Schnell, leistungsstark und innovativ. Diese Merkmale machen tinify.me zu einem der besten Bilder Kompressoren.</p>
			</Col>
		</ContentContainer>
	</Layout>
);

export default Index;
