import * as CompressionStatus from "../utils/CompressionStatus";
import { toHumanFileSize } from "../utils/Misc";
import IconCheck from "../images/icon-check.svg";
import IconCross from "../images/icon-cross.svg";
import { Col } from "reactstrap";
import React from "react";
import IconZIP from "../images/icon-zip.png";

const getZIPStatusString = (zip, images) => {
	switch (zip.status) {
		case CompressionStatus.ZIP_PREPARING:
			return "Bereite ZIP-Upload vor...";
		case CompressionStatus.ZIP_UPLOADING:
			return `Komprimiere ZIP-Bilder... ${ images.filter(image => image && image.status === CompressionStatus.COMPRESSION_DONE).length } / ${ zip.images.length } done.`;
		case CompressionStatus.ZIP_DONE:
			return `Jetzt: ${toHumanFileSize(getCompressedZIPSize(zip, images))}`;
		case CompressionStatus.ZIP_ERROR_UNZIP:
			return "Fehler beim entpacken!";
		case CompressionStatus.ZIP_ERROR_COMPRESSION:
			return "Fehler beim komprimieren der ZIP-Elemente!";
		default:
			return "Fehler!";
	}
};
const getTotalZIPProgress = (zip, images) => {
	switch (zip.status) {
		case CompressionStatus.ZIP_UPLOADING:
			return `${ images.filter(image => image && image.status === CompressionStatus.COMPRESSION_DONE).length / zip.images.length * 100 }%`;
		case CompressionStatus.ZIP_DONE:
			return "100%";
		default:
			return "0%";
	}
};
const getTotalZIPSavings = (zip, images) =>
	zip.status === CompressionStatus.ZIP_DONE
		? `-${ (100 - (getCompressedZIPSize(zip, images) / zip.sizeIn * 100)).toFixed(1) }%`
		: "";
const getCompressedZIPSize = (zip, images) =>
	images.reduce((prev, cur) => prev + cur.compressedFile.size, 0);


const getFileStatusString = elem => {
	switch (elem.status) {
		case CompressionStatus.COMPRESSION_UPLOADING:
			return `Lade hoch...`;
		case CompressionStatus.COMPRESSION_PROCESSING:
			return "Komprimiere...";
		case CompressionStatus.COMPRESSION_RECEIVING:
			return `Lade Daten...`;
		case CompressionStatus.COMPRESSION_DONE:
			return `Jetzt: ${ toHumanFileSize(elem.compressedFile.size) }`;
		case CompressionStatus.COMPRESSION_ERROR:
			return "Komprimierung fehlgeschlagen!";
		default:
			return "Fehler!";
	}
};
const getCompressionSavings = elem =>
	(elem.status === CompressionStatus.COMPRESSION_DONE && elem.file)
		? `-${ (100 - (elem.compressedFile.size / elem.file.size * 100)).toFixed(1) }%`
		: "";
const getTotalFileProgress = elem => {
	switch (elem.status) {
		case CompressionStatus.COMPRESSION_UPLOADING:
			return `${ (elem.progress.upload / 2) }%`;
		case CompressionStatus.COMPRESSION_PROCESSING:
			return "50%";
		case CompressionStatus.COMPRESSION_RECEIVING:
			return `${ 50 + (elem.progress.download / 2) }%`;
		case CompressionStatus.COMPRESSION_DONE:
			return "100%";
		default:
			return "0%";
	}
};

const DZEntry = ({ fileKey, image, onContext, zip, zipImages, onClick }) => {
	return (
		<Col xs={ 12 } className="dz-file" key={ `dz-entry-${ fileKey }` }
			 style={
				 image
				 ? { background: getTotalFileProgress(image) === "100%" ? "transparent" : `linear-gradient(to right, rgba(245, 175, 25, 0.1) ${ getTotalFileProgress(image) }, transparent ${ getTotalFileProgress(image) }, transparent)` }
				 : { background: getTotalZIPProgress(zip, zipImages) === "100%" ? "transparent" : `linear-gradient(to right, rgba(245, 175, 25, 0.1) ${ getTotalZIPProgress(zip, zipImages) }, transparent ${ getTotalZIPProgress(zip, zipImages) }, transparent)` }
			 }
			 onClick={ () => image ? (image.status === CompressionStatus.COMPRESSION_DONE && onClick()) : (zip.status === CompressionStatus.ZIP_DONE && onClick()) }
			 onContextMenu={ e => {
				 e.preventDefault();
				 onContext();
			 } }>
			<div className="p-3 mx-0">
				<div className="d-flex align-items-center">
					<div className="mr-3">
						<img src={
							image
							? (image.file ? URL.createObjectURL(image.file) : image.url)
							: IconZIP
						} height="48" width="48" className="dz-preview" alt="file preview"/>
					</div>
					<div className="mr-auto dz-wrapper">
						<span className="dz-name">
							{   image
								? (image.file ? image.file.path : image.url.substring(image.url.lastIndexOf("/") + 1))
								: zip.name
							}
						</span>
						<br/>
						{   !(image && image.url) &&
							<span className="dz-status mr-2">Vorher: { image ? toHumanFileSize(image.file.size) : toHumanFileSize(zip.sizeIn) }</span>
						}
						<span className="dz-status">
							{   image
								? getFileStatusString(image)
								: getZIPStatusString(zip, zipImages)
							}
						</span>
					</div>
					<span className="dz-savings float-right mr-3">
						{   image
							? (image.file ? getCompressionSavings(image) : image.status === CompressionStatus.COMPRESSION_DONE && "EXT")
							: getTotalZIPSavings(zip, zipImages)
						}
					</span>
					{	image &&
						(image.status === CompressionStatus.COMPRESSION_DONE || image.status === CompressionStatus.COMPRESSION_ERROR) &&
						<img src={ image.status === CompressionStatus.COMPRESSION_DONE ? IconCheck : IconCross } alt="file status"/>
					}
					{	!image &&
						(zip.status === CompressionStatus.ZIP_DONE
							|| zip.status === CompressionStatus.ZIP_ERROR_UNZIP
							|| zip.status === CompressionStatus.ZIP_ERROR_COMPRESSION) &&
						<img src={ zip.status === CompressionStatus.ZIP_DONE ? IconCheck : IconCross } alt="zip status"/>
					}
				</div>
			</div>
		</Col>
	)
};

export default DZEntry;
