import React, { Component } from "react";
import RangeSlider from "./RangeSlider";
import { connect } from "react-redux";
import { updateSimpleOption } from "../state/options";

class SimpleOptions extends Component {
	render() {
		return (
			<div>
				<RangeSlider label="PNG/JPG Qualität" value={ this.props.pQuality }
							 onChange={ value => this.props.dispatch(updateSimpleOption(value)) }
							 formatFunction={ value => `${ value }%` }/>
			</div>
		);
	}
}

export default connect(state => ({
	pQuality: state.options.pQuality,
}), null)(SimpleOptions);
