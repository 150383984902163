import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, FormText, FormFeedback } from "reactstrap";
import { updateURL, updateURLInvalid, compressURL } from "../state/files";

class UrlInput extends Component {

	handleEnter = (e) => {
		if (e.keyCode === 13) {
			if (!this.props.url.match(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+.*\.(png|jpg|jpeg|svg|gif)$/)) {
				this.props.dispatch(updateURLInvalid(true));
			} else {
				this.props.dispatch(compressURL({}));
			}
		}
	};

	render() {
		const { url, urlInvalid, dispatch } = this.props;
		return (
			<div className="mt-3">
				<Input type="url" name="url" id="imageUrl"
						placeholder="... oder füge hier eine URL ein"
						invalid={ urlInvalid }
						onKeyDown={ this.handleEnter }
						value={ url }
						onChange={ e => dispatch(updateURL(e.target.value)) }/>
				<FormText>URL muss auf ein Bild verweisen</FormText>
				<FormFeedback>Ungültige URL</FormFeedback>
			</div>
		);
	}
}

export default connect(state => ({
	url: state.files.url,
	urlInvalid: state.files.urlInvalid,
}), null)(UrlInput);
